import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Select } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function CreatePlayer(props) {
  const [post, setPost] = useState();
  const { mission_name } = props.match.params;
  const [playerName, setPlayerName] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });
  const fruitsList = [
    { value: "Screenshot", label: "lime-label" },
    { value: "Video", label: "mango-label" },
  ];

  // console.log(data);
  console.log("Count: ", data);
  useEffect(() => {
    (async () => {
      const result = await axios(`http://localhost:3000/api/runs/list`);
      setData(result.data);
    })();
  }, []);

  // Add New Players
  const addPlayer = (playerName, profilePicture) => {
    axios
      .get(
        `http://localhost:3000/api/players/add_player/${playerName}/${profilePicture}`
      )
      .then((response) => {
        console.log(response);
      });
  };

  // Remove Players
  const removePlayer = (id) => {
    axios
      .get(`http://localhost:3000/api/players/remove_player/${id}`)
      .then((response) => {
        console.log(response);
      });
  };

  // Edit Players
  const editPlayer = (id, player_name, profile_picture) => {
    axios
      .get(
        `http://localhost:3000/api/players/edit_player/${id}/${player_name}/${profile_picture}`
      )
      .then((response) => {
        console.log(response);
      });
  };

  return (
    <div className="App">
      {post}
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={`${mission_name} (Solo)`}
          columns={[
            {
              title: "Run ID",
              field: "id",
            },
            {
              title: "Mission ID",
              field: "mission_id",
            },
            {
              title: "Clear Time",
              field: "clear_time",
            },
            {
              title: "Created Date",
              field: "created_date",
            },
            {
              title: "Run Validation",
              field: "run_validation",
            },
            {
              title: "Server ID",
              field: "server_id",
            },
            {
              title: "Patch",
              field: "patch",
            },
            {
              title: "Player Count",
              field: "player_count",
            },
            {
              title: "Rank ID",
              field: "rank_id",
            },
            {
              title: "Validation Type",
              field: "validation_type",
              editComponent: ({ value, onChange }) => (
                <Select
                  options={fruitsList}
                  name="fruitSelect"
                  onChange={(selectedOption) => onChange(selectedOption.value)}
                  value={value ? value.value : value}
                />
              ),
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100, 500],
            pageSize: 10,
            headerStyle: { fontWeight: 700 },
            paginationType: "stepped",
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  setData([...data, newData]);
                  resolve();
                  console.log(newData);
                  addPlayer(newData.player_name, newData.profile_picture);
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  editPlayer(
                    newData.id,
                    newData.player_name,
                    encodeURIComponent(newData.profile_picture)
                  );
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  removePlayer(oldData.id);
                  resolve();
                }, 1000);
              }),
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default CreatePlayer;
