import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Table from "../Tables/Table";
import "../App.css";

import Players from "../Badges/PlayerBadges";
import Class from "../Badges/ClassBadges";

function Solo() {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "run_id",
      },
      {
        Header: "Link",
        accessor: "validation_type",
        Cell: (e) => <Link to={`/runs/${e.row.values.run_id}`}>{e.value}</Link>,
      },
      {
        Header: "Player Name",
        accessor: "player_names",
      },
      {
        Header: "Class",
        accessor: "class_names",
      },
      {
        Header: "Mission Name",
        accessor: "mission_name",
      },
      {
        Header: "Rank",
        accessor: "rank",
      },
      {
        Header: "Player Count",
        accessor: "player_count",
      },
      {
        Header: "Player Level",
        accessor: "player_level",
      },
      {
        Header: "Clear Time",
        accessor: "clear_time",
      },
      {
        Header: "Server",
        accessor: "server_name",
      },
    ],
    []
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios("https://psucta.com/api/official");
      setData(result.data);
    })();
  }, []);

  return (
    <div className="App">
      <h3>Official Server TAs</h3>
      <Table columns={columns} data={data} />
    </div>
  );
}

export default Solo;
