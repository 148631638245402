import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function LeaderboardsDuo(props) {
  const [post, setPost] = useState();
  var count = 0;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });
  // console.log(data);
  console.log("Count: ", data);
  console.log("Count: ", count);
  useEffect(() => {
    (async () => {
      const result = await axios(`https://psucta.com/api/party`);
      setData(result.data);
    })();
  }, []);

  return (
    <div className="App">
      {post}
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={
            <div className="leaderboard-type">
              <h4>Leaderboards (Party)</h4>
              <Link to={`/leaderboards/solo`}>Solo</Link>
              <Link to={`/leaderboards/duo`}>Duo</Link>
              <Link to={`/leaderboards/trio`}>Trio</Link>
              <Link to={`/leaderboards/party`}>Party</Link>
            </div>
          }
          columns={[
            {
              title: "",
              field: "profile_pictures",
              width: "100%",
              render: (rowData) => {
                var components = rowData.profile_pictures.map(function (item) {
                  return (
                    <div key={item}>
                      <img
                        src={item}
                        style={{ width: 20, borderRadius: "50%" }}
                      />
                    </div>
                  );
                });
                return <div>{components}</div>;
              },
              filtering: false,
              sorting: false,
              searchable: false,
            },
            {
              title: "Player Names",
              field: "player_names",
              render: (rowData) => {
                var components = rowData.player_names.map(function (item) {
                  return (
                    <div key={item}>
                      <Link to={`/players/${item}`}>{item}</Link>
                    </div>
                  );
                });
                return <div>{components}</div>;
              },
            },

            {
              title: "Mission Name",
              field: "mission_name",
              render: (rowData) => (
                <Link to={`/missions/solo/${rowData.mission_name}`}>
                  {rowData.mission_name}
                </Link>
              ),
            },
            { title: "Mission Rank", field: "rank_name" },
            { title: "Clear Time", field: "clear_time" },
            {
              title: "Class Names",
              field: "class_names",
              render: (rowData) => {
                var components = rowData.class_names.map(function (item) {
                  return (
                    <div key={item}>
                      <Link to={`/classes/${item}`}>{item}</Link>
                    </div>
                  );
                });
                return <div>{components}</div>;
              },
            },

            { title: "Player Level", field: "player_level" },

            {
              title: "Races",
              field: "race_name",
              render: (rowData) => {
                var components = rowData.race_name.map(function (item) {
                  return <div key={item}>{item}</div>;
                });
                return <div>{components}</div>;
              },
            },
            { title: "Patch", field: "patch" },
            {
              title: "Validation Type",
              field: "validation_type",
              render: (rowData) => (
                <Link to={`/runs/${rowData.run_id}`}>
                  {rowData.validation_type}
                </Link>
              ),
            },
            {
              title: "Date Added",
              field: "created_date",
              type: "date",
              dateSetting: {
                format: "dd/MM/yyyy",
              },
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100, 500],
            pageSize: 5,
            headerStyle: { fontWeight: 700 },
            paginationType: "stepped",
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default LeaderboardsDuo;
