import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function RunSingle(props) {
  const [post, setPost] = useState();
  const { id } = props.match.params;
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/runs/${id}`)
      .then((response) => response.json())
      .then((post) => {
        //console.log(post[0].run_validation.toString().startsWith("https://imgur"));
        if (post[0].run_validation == null) {
          let postData = (
            <div className="post" key={post.id}>
              <div className="container"></div>
            </div>
          );
          setPost(postData);
        } else if (
          post[0].run_validation.toString().startsWith("https://www.youtube")
        ) {
          let postData = (
            <div className="post" key={post.id}>
              <div className="container">
                <div className="row">
                  <div className="runMedia">
                    <iframe
                      width="1280"
                      height="720"
                      src={post[0].run_validation}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
          setPost(postData);
        } else if (
          post[0].run_validation
            .toString()
            .startsWith("https://embed.nicovideo")
        ) {
          let postData = (
            <div className="post" key={post.id}>
              <div className="container">
                <div className="row">
                  <div className="runMedia">
                    <iframe
                      width="1280"
                      height="720"
                      src={post[0].run_validation}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded nicovideo"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
          setPost(postData);
        } else if (
          post[0].run_validation.toString().startsWith("https://i.imgur") ||
          post[0].run_validation.toString().includes("discord")
        ) {
          let postData = (
            <div className="post" key={post.id}>
              <div className="container">
                <div className="row">
                  <div className="runMedia">
                    <img
                      src={post[0].run_validation}
                      className="run-pic"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          );
          setPost(postData);
        }
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  const { mission_name } = props.match.params;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });

  useEffect(() => {
    (async () => {
      const result = await axios(`https://psucta.com/api/runs/${id}`);
      setData(result.data);
    })();
  }, []);
  return (
    <div className="App">
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={``}
          columns={[
            {
              title: "",
              field: "profile_pictures",
              width: "100%",
              render: (rowData) => (
                <img
                  src={rowData.profile_picture}
                  style={{ width: 50, borderRadius: "50%" }}
                />
              ),
              filtering: false,
              sorting: false,
              searchable: false,
            },
            {
              title: "Player Names",
              field: "player_names",
              render: (rowData) => (
                <Link to={`/players/${rowData.player_name}`}>
                  {rowData.player_name}
                </Link>
              ),
            },
            {
              title: "Mission Name",
              field: "mission_name",
              render: (rowData) => (
                <Link to={`/missions/solo/${rowData.mission_name}`}>
                  {rowData.mission_name}
                </Link>
              ),
            },
            { title: "Mission Rank", field: "rank" },
            { title: "Clear Time", field: "clear_time" },
            {
              title: "Class Names",
              field: "class_names",
              render: (rowData) => (
                <Link to={`/classes/${rowData.class_name}`}>
                  {rowData.class_name}
                </Link>
              ),
            },
            { title: "Races", field: "player_race" },
            { title: "Player Level", field: "player_level" },
            { title: "Patch", field: "patch" },
            {
              title: "Date Added",
              field: "created_date",
              type: "date",
              dateSetting: {
                format: "dd/MM/yyyy",
              },
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: true,
            paging: false,
            pageSize: 6,
            headerStyle: { fontWeight: 700 },
          }}
        />
      </MuiThemeProvider>
      {post}
    </div>
  );
}

export default RunSingle;
