import React from "react";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/solid";
import HeaderItem from "./HeaderItem";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

function Header() {
  return (
    <div>
      <Navbar fixed="top" sticky="top" bg="none" variant="dark">
        <div className="header-logo">
          {/* <Navbar.Brand href="/">PSUC: Time Attack</Navbar.Brand> */}
        </div>
        <div className="menu-items">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="justify-content-center">
              {/* <Nav.Link href="/">Home</Nav.Link> */}
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/records/solo/8">Records</Nav.Link>
              <Nav.Link href="/players/solo">Players</Nav.Link>

              {/* <Nav.Link href="/solo">Solo</Nav.Link>
              <Nav.Link href="/duo">Duo</Nav.Link>
              <Nav.Link href="/party">Party</Nav.Link> */}
              <Nav.Link href="/leaderboards/solo">Leaderboards</Nav.Link>
              <Nav.Link href="/events">Events</Nav.Link>
              {/* <Nav.Link href="/official">Official</Nav.Link> */}

              {/* <NavDropdown title="Events" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Event 1</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Event 2</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Event 3</NavDropdown.Item>
              </NavDropdown> */}
              <NavDropdown title="Links" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://psu-clementine.net/wiki/index.php/Main_Page">
                  Clementine Wiki
                </NavDropdown.Item>
                <NavDropdown.Item href="http://re-psupedia.info/">
                  re-PSUPedia
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="https://discord.gg/Cn3PCxbwdS">
                  Time Attack Discord
                </NavDropdown.Item>
                <NavDropdown.Item href="https://discord.gg/NTVGJG9">
                  Clementine Discord
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/* <div className="leftSide">
        <div className="links">
          <div>
            <HeaderItem title="HOME" Icon={HomeIcon} />
          </div>
          <Link to={`/`}>Home</Link>
          <Link to={`/players`}>Players</Link>
          <Link to={`/solo`}>Solo</Link>
          <Link to={`/duo`}>Duo</Link>
          <Link to={`/party`}>Party</Link>
          <Link to={`/official`}>Official</Link>
          <Link to={`/leaderboards`}>Leaderboards</Link>
        </div>
      </div>
      <div className="rightSide"></div> */}
    </div>
  );
}
export default Header;
