import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import Table from "../Tables/Table";
import "../App.css";

function PlayerDuo(props) {
  const [post, setPost] = useState();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [data, setData] = useState([]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });
  // console.log(data);
  console.log("Count: ", data);
  useEffect(() => {
    (async () => {
      const result = await axios(`https://psucta.com/api/players/duo`);
      setData(result.data);
    })();
  }, []);

  return (
    <div className="App">
      {post}
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={
            <div className="leaderboard-type">
              <h4>Players (Duo)</h4>
              <Link to="/players/solo">Solo</Link>
              <Link to="/players/duo">Duo</Link>
              <Link to="/players/trio">Trio</Link>
              <Link to="/players/party">Party</Link>
            </div>}
          columns={[
            {
              title: "Player Rank",
              field: "player_rank",
              width: "100%",
              filtering: false,
              searchable: false,
            },
            {
              title: "Player Names",
              field: "player_name",
              render: (rowData) => (
                <div>
                  <img
                    src={rowData.profile_picture}
                    style={{ width: 50, borderRadius: "50%", marginRight: 10 }}
                  />
                  <Link to={`/players/${rowData.player_name}`}>
                    {rowData.player_name}
                  </Link>
                </div>
              ),
            },
            {
              title: "Ultimate Records",
              field: "record_count",
              filtering: false,
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: true,
            paging: true,
            pageSizeOptions: [5, 10, 20, 50, 100, 500],
            pageSize: 10,
            headerStyle: { fontWeight: 700 },
            paginationType: "stepped",
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default PlayerDuo;
