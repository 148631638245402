import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Moment from "moment";

function RunSingle(props) {
  const [post, setPost] = useState();
  const { id } = props.match.params;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#ff9100",
      },
      secondary: {
        main: "#ff9100",
      },
      type: "dark",
    },
  });
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log(props.match);
    fetch(`https://psucta.com/api/events/${id}`)
      .then((response) => response.json())
      .then((post) => {
        //console.log(post[0].run_validation.toString().startsWith("https://imgur"));
        let postData = (
          <div className="post" key={post.id}>
            <h1 className="title-heading">{post.event_name}</h1>
            <div className="container">
              <h3 className="event-dates">
                {Moment(post.event_date).utc().format("ddd, MMM D, YYYY h:mmA")}{" "}
                EST —{" "}
                {Moment(post.event_end_date)
                  .utc()
                  .format("ddd, MMM D, YYYY h:mmA")}{" "}
                EST
              </h3>
              <h3 className="general-rules">General Rules</h3>
              <ol type="1">
                <li>No methods of glitching/cheating are allowed.</li>
                <li>All participants' classes used must be level 20+.</li>
                <li>
                  If a team member is unable to participate, player substitutes
                  may be used. However, once a substitue has played for a team,
                  they are not allowed to play for any other team.
                </li>
              </ol>
              <h3 className="event-rules">Event Specific Rules</h3>
              <ol type="1">
                <li>{post.player_count_description}</li>
                <li>
                  {post.random_teams_description}
                  <a href={post.event_link}>Sign-up</a>
                </li>
                <li>{post.class_restrictions_description}</li>
                <li>{post.duplicate_classes_description}</li>
                <li>{post.class_swaps_description}</li>
                <li>{post.format_description}</li>
                <li>{post.type_description}</li>
                <li>
                  Rewards will be distributed between the top 3 teams. All
                  donations are welcome — The total jackpot is:{" "}
                  <b>{post.event_prize}</b>.
                </li>
              </ol>
              <h3 className="event-rules">Event Bracket</h3>
              <iframe
                src={post.event_bracket}
                width="100%"
                height="700"
                frameborder="0"
                scrolling="auto"
                allowtransparency="true"
              ></iframe>
            </div>
          </div>
        );
        setPost(postData);
      });
  }, []); //Passing in an empty array is equvialent to component did mount!

  useEffect(() => {
    (async () => {
      const result = await axios(
        `https://psucta.com/api/events/${id}/missions`
      );
      setData(result.data);
    })();
  }, []);
  return (
    <div className="App">
      {post}
      {/* <Table columns={columns} data={data} /> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title={`Event Missions`}
          columns={[
            {
              title: "Round",
              field: "description",
            },
            {
              title: "Mission Name",
              field: "mission_name",
              render: (rowData) => (
                <Link to={`/missions/${rowData.mission_name}`}>
                  {rowData.mission_name}
                </Link>
              ),
            },
          ]}
          data={data}
          icons={tableIcons}
          options={{
            filtering: false,
            search: false,
            paging: false,
            pageSize: 6,
            headerStyle: { fontWeight: 700 },
          }}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default RunSingle;
